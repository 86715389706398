



















import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: '' }) dueDate!: ''
  @Prop({ default: false }) shorten
  get isPassedDueDate() {
    if (!this.dueDate) return false
    return moment().isAfter(moment(this.dueDate))
  }
}
